<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <breadcrumb />
      <section class="clearfix" style="margin-bottom: 10px">
        <!-- 新建规则 -->
        <a-button v-if="priv('f_gift_stategy_add')" type="primary" @click="newStrategyList">
          <i class="iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t(`新建规则`) }}
        </a-button>
      </section>

      <a-table
        :columns="columns"
        :dataSource="giftStrategyList"
        :pagination="false"
        :customRow="customRow"
        :scroll="{ x: 1000 }"
        :rowKey="
          (record, index) => {
            return index
          }
        "
      >
        <!-- 规则编号 -->
        <template slot="RuleNumber" slot-scope="text, record">
          <p>{{ record.code }}</p>
        </template>

        <!-- 赠品明细 -->
        <template slot="GiftDetails" slot-scope="text, record">
          <p>{{ record.express }}</p>
        </template>

        <!-- 赠品余量/总量 -->
        <template slot="UnitPrice" slot-scope="text, record">
          <p v-if="!record.maxWeight">{{ record.minWeight }}KG {{ $t(`以上`) }}</p>
          <p v-else>{{ record.minWeight }}KG - {{ record.maxWeight }}KG</p>
        </template>
      </a-table>

      <div class="table-footer clearfix">
        <pagination
          :offset="giftStrategyData.offset"
          :length="giftStrategyData.length"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </section>
    <a-modal
      :title="$t(`新建快递规则`)"
      :visible="visible"
      @ok="handlerClick"
      @cancel="cancelNoteAdd"
      :destroyOnClose="true"
    >
      <a-form-model
        :model="formData"
        ref="formRef"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 8 }"
        autocomplete="off"
        :layout="'horizontal'"
      >
        <a-form-model-item
          :label="$t(`快递`)"
          prop="express"
          required
          :rules="[{ required: true, message: $t('请选择快递') }]"
        >
          <a-select ref="select" v-model="formData.express" style="width: 120px">
            <a-select-option :value="'JT'">{{ $t(`JT`) }}</a-select-option>
            <a-select-option :value="'Flash'">{{ $t(`Flash`) }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="$t(`重量区间`) + '(KG)'" prop="maxWeight">
          <a-input style="width: 100px" v-model="formData.minWeight" />
          -
          <a-input style="width: 100px" v-model="formData.maxWeight" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import pagination from '@component/pagination'

export default {
  name: 'giftRule',
  components: {
    pagination,
  },
  mounted() {
    let giftStrategyList = [
      {
        code: 1,
        express: 'JT',
        minWeight: 0,
        maxWeight: 6,
      },
      {
        code: 2,
        express: 'Flash',
        minWeight: 6,
        maxWeight: '',
      },
    ]
    if (!localStorage.getItem('giftStrategyList')) {
      localStorage.setItem('giftStrategyList', JSON.stringify(giftStrategyList))
    }
    this.getGiftStrategyList()
  },
  data() {
    return {
      giftStrategyData: {
        offset: 0,
        length: 10,
      },
      pageData: {
        totalCount: '',
      },
      giftStrategyList: [],
      visible: false,
      formData: {
        express: '',
        minWeight: '',
        maxWeight: '',
      },
      total: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t(`规则编号`),
          scopedSlots: { customRender: 'RuleNumber' },
          key: 'RuleNumber',
          width: 200,
        },
        {
          title: this.$t(`快递`),
          scopedSlots: { customRender: 'GiftDetails' },
          key: 'GiftDetails',
          width: 200,
        },
        {
          title: this.$t(`重量区间`),
          scopedSlots: { customRender: 'UnitPrice' },
          key: 'UnitPrice',
          width: 200,
        },
      ]
    },
  },
  methods: {
    getGiftStrategyList() {
      let list = JSON.parse(localStorage.getItem('giftStrategyList'))
      this.total = list.length
      this.giftStrategyList = list.slice(
        this.giftStrategyData.offset,
        this.giftStrategyData.offset + this.giftStrategyData.length
      )
    },
    handlerClick() {
      if (!this.formData.minWeight) {
        this.$message.warning(this.$t(`请填写重量区间`))
        return
      }
      if (this.formData.minWeight > this.formData.maxWeight) {
        this.$message.warning(this.$t(`重量区间最低值不能大于最高值`))
        return
      }
      let list = JSON.parse(localStorage.getItem('giftStrategyList'))
      let len = list.length
      let index = list[len - 1].code
      let obj = {
        code: index + 1,
        express: this.formData.express,
        minWeight: this.formData.minWeight,
        maxWeight: this.formData.maxWeight,
      }
      list.push(obj)
      localStorage.setItem('giftStrategyList', JSON.stringify(list))
      this.$message.success(this.$t(`新增成功`))
      this.getGiftStrategyList()
      this.cancelNoteAdd()
    },
    cancelNoteAdd() {
      this.visible = false
      this.formData = {
        express: '',
        minWeight: '',
        maxWeight: '',
      }
    },
    // 已停用的背景色修改
    customRow(record) {
      return {
        style: {
          color: record.status == 'UNABLE' ? '#ccc' : 'rgba(0, 0, 0, 0.65)',
        },
      }
    },
    paginate(data) {
      this.giftStrategyData.offset = data.offset
      this.giftStrategyData.length = data.length
      this.getGiftStrategyList()
    },
    newStrategyList() {
      this.visible = true
    },
  },
}
</script>

<style scoped>
.tr-grey {
  color: #ccc;
}

.detailName {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}

.td-red {
  color: #ff3f3f;
}
</style>
